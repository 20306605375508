import React from 'react';
import { observer } from 'mobx-react';

import styles from './select-list-modal.module.scss';

import { TextButton } from '~/components/Buttons/Components/TextButton';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { Button } from '~/components/Buttons/Components/Button';
import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';
import { ListOption } from '~/product/common/interstitial/favorites/Components/ListOption';
import { useGlobalContext } from '~/global/Contexts/Global.context';

interface Props {
	store: AddToFavoritesStore
}

export const SelectAFavoriteList = observer((props: Props) => {
	const {
		store,
		store: {
			lists = [],
			saveError = false,
			isSaveDisabled = false,
		} = {},
	} = props;

	const {
		magicModal,
	} = useGlobalContext();

	const createNewListButtonHandler = () => {
		store.switchToCreateANewListModal();
	};

	return (
		<div>
			<ul className={styles['list-of-lists']}>
				{
					lists.map((list) => {
						const {
							id,
						} = list;
						return (
							<ListOption store={store} list={list} key={`list-option-${id}`} />
						);
					})
				}
			</ul>

			<div>
				<TextButton data-qa="create-new-list-button" onClick={createNewListButtonHandler}>
					+ Create new list
				</TextButton>
			</div>

			<div className={`${styles['buttons']} tw-space-x-6`}>
				<Button
					data-qa="add-to-favorites-save-button"
					disabled={isSaveDisabled}
					onClick={() => {
						store.submitHandler();
					}}
					variant={ButtonVariant.Primary}
				>
					Save
				</Button>
				<TextButton
					type="button"
					data-qa="add-to-favorites-cancel-button"
					onClick={() => {
						magicModal.closeModal();
					}}
					data-tr-link-event-track="false"
				>
					Cancel
				</TextButton>
			</div>

			{
				Boolean(saveError) &&
				<div className="error tw-mt-4 tw-text-red">
					Unable to save changes. Please try again.
				</div>
			}
		</div>
	);
});
