import {
	computed, observable, action, makeObservable,
} from 'mobx';

import { swatchTypeMessages } from '~/product/common/swatchViewer/swatchViewer.constants';
import { SWATCH_ORDER_TYPES } from '~/product/common/swatch-order/Settings/SwatchOrder.settings';

class SwatchModalModel {
	data; // Mobx Map

	groups;

	showSelectionRequiredError;

	isFullDetailsExpanded;

	isResponsibleDetailsExpanded;

	orderContentType;

	addSwatchesToCartResponse;

	addSwatchesToWishlistResponse;

	breadcrumbModels;

	TYPE_MAPPINGS = {
		DEFAULT: 'DEFAULT',
		SUMMARY_PAID_MATERIAL_SAMPLE: 'PAID_MATERIAL_SAMPLE',
		SUMMARY_PAID_TOP_SAMPLE: 'PAID_TOP_SAMPLE',
		SUMMARY_PAID_WOOD_SAMPLE: 'PAID_WOOD_SAMPLE',
	};

	constructor() {
		makeObservable(this, {
			data: observable,
			groups: observable,
			showSelectionRequiredError: observable,
			isFullDetailsExpanded: observable,
			isResponsibleDetailsExpanded: observable,
			orderContentType: observable,
			addSwatchesToCartResponse: observable,
			addSwatchesToWishlistResponse: observable,
			update: action.bound,
			addToFavoritesHref: computed,
			id: computed,
			title: computed,
			articleNumber: computed,
			type: computed,
			typeText: computed,
			typeMessage: computed,
			promoKey: computed,
			selectedGroup: computed,
			hasSampleArticles: computed,
			hasSwatchDetails: computed,
			contentType: computed,
			selectedSwatch: computed,
			selectedSwatches: computed,
			isOrderable: computed,
			orderSettings: computed,
			showMaterialDetailsLink: computed,
		});
	}

	update(newData, groups = []) {
		if (newData) {
			this.data.replace(newData);
		} else {
			this.data.clear();
		}

		this.groups.replace(groups);
	}

	get id() {
		return this.data.get('id');
	}

	get title() {
		return this.data.get('modalTitle');
	}

	get articleNumber() {
		return this.data.get('articleNumber');
	}

	get type() {
		return this.data.get('type');
	}

	get typeText() {
		return this.type === 'MATERIAL' ? 'Photo Card' : 'Swatch';
	}

	get typeMessage() {
		const typeMessage = swatchTypeMessages[this.type];

		return typeMessage || swatchTypeMessages.DEFAULT;
	}

	get promoKey() {
		return this.data.promo?.key;
	}

	get selectedGroup() {
		if (!this.groups || !this.groups.length) {
			return undefined;
		}
		const selectedGroup = this.groups.find((group) => {
			return group.selectedSwatch;
		});
		return selectedGroup;
	}

	get hasSampleArticles() {
		if (!this.groups || !this.groups.length) {
			return false;
		}
		return this.groups.some((group) => {
			return group.swatches.some((swatch) => {
				return Boolean(swatch.sampleArticleNumber);
			});
		});
	}

	get hasSwatchDetails() {
		if (!this.groups || !this.groups.length) {
			return false;
		}
		return this.groups.some((group) => {
			return group.swatches.some(({ details = [], topDetails = [], fullDetails = [] }) => {
				return details.length || topDetails.length || fullDetails.length;
			});
		});
	}

	get contentType() {
		if (!this.groups || !this.groups.length) {
			return undefined;
		}

		if (this.orderContentType) {
			const contentType = this.TYPE_MAPPINGS[this.orderContentType];

			if (contentType) {
				return contentType;
			}
			return this.TYPE_MAPPINGS.DEFAULT;
		}

		if (this.type === 'MATERIAL' && this.hasSampleArticles) {
			return this.TYPE_MAPPINGS.SUMMARY_PAID_TOP_SAMPLE;
		}

		return this.TYPE_MAPPINGS.DEFAULT;
	}

	get selectedSwatch() {
		return this.selectedGroup?.selectedSwatch;
	}

	get selectedSwatches() {
		const swatches = [];
		this.groups.forEach((group) => {
			swatches.push(...group.selectedSwatches);
		});
		return swatches;
	}

	get isOrderable() {
		return this.data.get('orderable');
	}

	get orderSettings() {
		if (this.type && this.contentType) {
			return SWATCH_ORDER_TYPES[this.type][this.contentType];
		}
		return {};
	}

	get showMaterialDetailsLink() {
		return this.data.get('showMaterialDetailsLink');
	}

	get addToFavoritesHref() {
		return this.data.get('_links')?.addToFavorites?.href;
	}

	get sourceBreadcrumbs() {
		if (!this.breadcrumbModels) {
			return {};
		}

		const sourceCrumbs = {};

		this.breadcrumbModels.forEach((crumb) => {
			const { url = '', type = '' } = crumb.data[0];
			if (!url || !type) {
				return;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
		});
		return sourceCrumbs;
	}
}

const SwatchModalModelFactory = {
	create: (orderContentType, breadcrumbModels) => {
		const instance = new SwatchModalModel();
		instance.data = new Map();
		instance.groups = [];
		instance.orderContentType = orderContentType;
		instance.isFullDetailsExpanded = false;
		instance.isResponsibleDetailsExpanded = false;
		instance.breadcrumbModels = breadcrumbModels;

		return instance;
	},
};

export { SwatchModalModelFactory };
