import React from 'react';

import type { LINE_ITEM_MESSAGE } from '~/util/messaging/line-item/Models/LineItemMessage.constants';
import type { ContextKey } from '~/util/messaging/Messaging.type';
import { LineItemMessages } from '~/util/messaging/line-item/LineItemMessages';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import css from '~/util/messaging/line-item/LineItemMessaging.module.scss';

type Props = {
	className?: string
	context?: ContextKey
	messages: {
		messageKey: LINE_ITEM_MESSAGE
		messageValues?: unknown
	}[]
	parentKey?: string
	readOnly?: boolean
}

export const LineItemMessaging = ({
	className = css.lineItemMessaging, context, messages = [], parentKey, readOnly,
}: Props) => {
	const {
		featureTogglesModel: {
			isOn = () => { return false; }
		} = {}
	} = useGlobalContext();

	if (messages.length === 0) {
		return null;
	}

	return (
		<div className={className}>
			{
				messages.map(({ messageKey, messageValues }, index) => {
					const MessageTag = LineItemMessages[messageKey] || (isOn('DEBUG_MODE') && LineItemMessages.MISSING);

					if (!MessageTag) {
						return null;
					}
					return (
						<MessageTag
							context={context}
							key={`${parentKey}-message-${index}`}
							messageKey={messageKey}
							readOnly={readOnly === undefined ? false : readOnly}
							values={messageValues}
						/>
					);
				})
			}
		</div>
	);
};
