import {
	type Dispatch, type SetStateAction, type MutableRefObject,
} from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export type MenuRegistrationRecord = {
	menuId: string,
	useStateFn: Dispatch<SetStateAction<boolean>>
};
type MenusRef = MutableRefObject<MenuRegistrationRecord[]>;
type UseForbidMultipleOpenMenusHook = [
	(registration: MenuRegistrationRecord) => void,
	(menuId: MenuRegistrationRecord['menuId']) => void,
	(openedMenuId: string) => void,
	MenusRef,
];

export const useForbidMultipleOpenMenus = (): UseForbidMultipleOpenMenusHook => {
	const menus: MenusRef = useGlobalContext().menus;
	return [
		function registerMenu(registration: MenuRegistrationRecord) {
			if (!menus?.current) {
				return;
			}
			const selectedMenu = menus.current.find(m => m.menuId === registration.menuId);

			if (selectedMenu) {
				selectedMenu.menuId = registration.menuId;
				selectedMenu.useStateFn = registration.useStateFn;
				return;
			}

			menus.current.push({
				menuId: registration.menuId,
				useStateFn: registration.useStateFn,
			});
		},
		function deregisterMenu(menuId: MenuRegistrationRecord['menuId']) {
			if (!menus?.current?.length) {
				return;
			}
			const index = menus.current.findIndex(item => item.menuId === menuId);

			if (index === -1) {
				return;
			}
			menus.current.splice(index, 1);
		},
		function closeAllOtherMenus(openedMenuId: MenuRegistrationRecord['menuId']) {
			if (!menus?.current) {
				return;
			}
			menus.current.forEach((menu) => {
				if (menu.menuId === openedMenuId) {
					return;
				}
				menu.useStateFn(false);
			});
		},
		menus,
	];
};
