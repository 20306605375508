import {
	computed, observable, makeObservable,
} from 'mobx';

class SwatchModel {
	data; // Mobx Object

	isSelected;

	detailsMap;

	constructor() {
		makeObservable(this, {
			data: observable,
			isSelected: observable,
			name: computed,
			imagePath: computed,
			type: computed,
			materialId: computed,
			articleNumber: computed,
			favoritesProductPayload: computed,
			sampleArticleNumber: computed,
			isOrderable: computed,
			_links: computed,
			responsibleDetails: computed,
			topDetails: computed,
			fullDetails: computed,
			defaultSelectedState: computed,
		});
	}

	get name() {
		return this.data.name;
	}

	get imagePath() {
		return this.data.image;
	}

	get type() {
		return this.data.type;
	}

	get materialId() {
		return this.data.materialId;
	}

	get articleNumber() {
		return this.data.articleNumber;
	}

	get sampleArticleNumber() {
		return this.data.sampleArticleNumber;
	}

	get favoritesProductPayload() {
		return {
			articleNumber: this.sampleArticleNumber,
			quantity: 1,
			salesText: this.name,
		};
	}

	get isOrderable() {
		return Boolean(this.data.orderable);
	}

	get _links() {
		return this.data._links;
	}

	get responsibleDetails() {
		return this.data.responsibleDetails.map((detail) => {
			return this.detailsMap[detail];
		});
	}

	get topDetails() {
		return this.data.topDetails.map((detail) => {
			return this.detailsMap[detail];
		});
	}

	get fullDetails() {
		return this.data.fullDetails.map((detail) => {
			return this.detailsMap[detail];
		});
	}

	get defaultSelectedState() {
		return this.data.selected;
	}
}

const SwatchModelFactory = {
	create: (data = {}, detailsMap = {}) => {
		const instance = new SwatchModel();
		instance.data = data;
		instance.detailsMap = detailsMap;
		instance.isSelected = false;

		return instance;
	},
};

export { SwatchModelFactory };
