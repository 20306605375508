'use client';

import React, { PropsWithChildren, useEffect } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import classNames from 'classnames';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

import { isCypress } from '~/global/global.constants';

export interface IProps extends NextLinkProps {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
	className?: string;
	suppressHydrationWarning?: boolean;
	title?: string;
}

export const NextJsTextLink = (props: PropsWithChildren<IProps>) => {
	const {
		color,
		hasNoUnderline,
		className,
		...nextProps
	} = props;
	const cn = classNames(className, buildTextLinkClassNames(color, hasNoUnderline));

	const [isHydrated, setIsHydrated] = React.useState(false);
	const [useClientSideNavigation, setUseClientSideNavigation] = React.useState(true);

	useEffect(() => {
		setIsHydrated(true);
		if (isCypress) {
			setUseClientSideNavigation(false);
		}
	}, []);

	if (!useClientSideNavigation) {
		const {
			prefetch,
			...anchorProps
		} = nextProps;
		return (
			// @ts-ignore
			<a
				{...anchorProps}
				className={cn}
				data-is-hydrated={isHydrated}
			>
				{props.children}
			</a>
		);
	}

	return (
		<NextLink
			prefetch={false}
			{...nextProps}
			className={cn}
			data-is-hydrated={isHydrated}
		>
			{props.children}
		</NextLink>
	);
};
