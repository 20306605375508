import { AddedProductGroup } from '~/favorites/add/Models/AddedProductGroup.model';
import { IAddedProductGroups } from '~/favorites/Types/Favorites.interface';
import { ImageModel } from '~/util/images/Image.model';

export const createAddedProductGroup = (data: IAddedProductGroups) => {
	const {
		collectionName,
		salesText,
		shortSalesText,
		imageUrl,
		quantity,
		unitPrice,
	} = data || {};

	return new AddedProductGroup({
		...(imageUrl) && {
			image: new ImageModel({
				imagePath: imageUrl,
				width: 100,
				height: 100,
				mediumWidth: 220,
				mediumHeight: 200,
			}),
		},
		collectionName,
		name: salesText,
		shortName: shortSalesText,
		quantity,
		unitPrice,
	});
};
