import {
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { ImageModel } from '~/util/images/Image.model';

import { modelNamespace } from '~/util/modelNamespace';

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

@model(`${modelNamespace.FAVORITES}/AddedProductGroup`)
export class AddedProductGroup extends Model({
	id: idProp.withSetter(),
	collectionName: prop<string>(),
	name: prop<string>(),
	shortName: prop<string>(),
	image: prop<ImageModel | undefined>(),
	quantity: prop<number>(),
	unitPrice: prop<number>(),
}) {
	get hasImage() {
		return Boolean(this.image);
	}

	get formattedUnitPrice() {
		return formatter.format(this.unitPrice);
	}

	get subTotal() {
		return this.unitPrice * this.quantity;
	}

	get formattedSubTotal() {
		return formatter.format(this.subTotal);
	}
}
