import { action, observable, makeObservable } from 'mobx';
import axios from 'axios';

import { apiUrl, isOnServer } from '~/global/global.constants';
import { SwatchModalModelFactory } from '~/product/common/swatchViewer/Models/SwatchModal.model';
import { SwatchGroupModelFactory } from '~/product/common/swatchViewer/Models/SwatchGroup.model';
import { swatchViewerModalSettings } from '~/product/common/swatchViewer/swatchViewer.constants';
import { SwatchModelFactory } from '~/product/common/swatchViewer/Models/Swatch.model';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';
import { createFavoriteSummaries } from '~/favorites/list/Factories/FavoritesSummary.factory';
import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';

const ENDPOINTS = {
	ARTICLES: 'multi-product',
	SWATCHES: 'swatches',
};

class SwatchViewerStore {
	HREF;

	featureTogglesModel;

	productGroupKey;

	model;

	renderMode;

	url;

	isLoading;

	shouldButtonAnimate;

	isAddToCartInProcess;

	isAddToCartDone;

	shouldSampleButtonAnimate;

	isAddSampleToCartInProcess;

	isAddSampleToCartDone;

	addToCartType;

	orderContentType;

	cookies = {};

	magicModal = {};

	globalDynamicStore = {};

	constructor() {
		makeObservable(this, {
			model: observable,
			renderMode: observable,
			url: observable,
			isLoading: observable,
			shouldButtonAnimate: observable,
			isAddToCartInProcess: observable,
			isAddToCartDone: observable,
			shouldSampleButtonAnimate: observable,
			isAddSampleToCartInProcess: observable,
			isAddSampleToCartDone: observable,
			addToCartType: observable,
			orderContentType: observable,
			cookies: observable.ref,
			magicModal: observable.ref,
			globalDynamicStore: observable.ref,
			setRenderMode: action.bound,
			setOrderContentType: action.bound,
			preSelectSwatches: action.bound,
			addSwatchToCart: action.bound,
			addSampleToCart: action.bound,
			addSampleToFavorites: action.bound,
			addToWishList: action.bound,
			addToCart: action.bound,
			addSwatchesToCart: action.bound,
			stopAnimating: action.bound,
			addToCartErrorHandler: action.bound,
			fetchData: action,
			processData: action.bound,
			deselectAllSwatches: action.bound,
			selectSwatch: action.bound,
			toggleSwatchSelected: action.bound,
			toggleFullDetails: action.bound,
			toggleResponsibleDetails: action.bound,
		});
	}

	setRenderMode(renderMode) {
		const modalSettings = swatchViewerModalSettings[renderMode];

		if (modalSettings) {
			this.magicModal.alterModal(modalSettings);
		}

		this.renderMode = renderMode;
		this.preSelectSwatches();
	}

	setOrderContentType(orderContentType = '') {
		this.model.orderContentType = orderContentType;
		this.orderContentType = orderContentType;

		const {
			orderSettings: {
				modalContent: {
					modalTitle: title = '',
				} = {},
			} = {},
		} = this.model;

		this.magicModal.alterModal({ title });
	}

	preSelectSwatches() {
		// deselect first
		this.deselectAllSwatches();

		if (this.renderMode === 'ORDER') {
			if (this.model.groups.length === 1 && this.model.groups[0].swatches.length === 1) {
				this.model.groups[0].swatches[0].isSelected = true;
			}
		} else if (this.renderMode === 'DETAIL') {
			this.model.groups.forEach((group) => {
				group.swatches.forEach((swatch) => {
					swatch.isSelected = swatch.defaultSelectedState;
				});
			});
		}
	}

	addSwatchToCart(materialId) {
		this.addToCartType = 'CART';
		return this.addSwatchesToCart({
			materialIds: [materialId],
		}, ENDPOINTS.SWATCHES);
	}

	addSampleToFavorites() {
		const promise = new Promise((resolve, reject) => {
			axios
				.get(`${apiUrl}/api/favorites`)
				.then((response) => {
					const {
						data,
					} = response;

					const lists = createFavoriteSummaries(data);

					const defaultList = lists.find((summary) => {
						return summary.defaultList;
					});

					const payload = {
						addToFavoritesPayload: {
							...(this.productGroupKey && {
								productGroupKey: this.productGroupKey,
							}),
							products: this.model.selectedSwatches.map((model) => {
								return model.favoritesProductPayload;
							}),
							sourceBreadcrumbs: this.model.sourceBreadcrumbs,
						},
					};

					const store = new AddToFavoritesStore({
						addToFavoritesAPI: this.model.addToFavoritesHref,
						lists,
						productDTO: payload,
						trackingEventsStore: new FavoritesTrackingEventsStore({}),
					});

					store.featureTogglesModel = this.featureTogglesModel;
					store.magicModal = this.magicModal;
					store.successCallback = resolve;
					store.failureCallback = reject;

					store.updateSelectedState(defaultList.id, true);
					store.submitHandler();
				});
		});

		promise.then(() => {
			this.magicModal.closeModal();
			this.globalDynamicStore.fetchData();
		});

		return promise;
	}

	addSampleToCart(articleNumber) {
		this.addToCartType = 'CART';
		return this.addSwatchesToCart({
			productRequestDtos: [{ articleNumber }],
		}, `shopping/${ENDPOINTS.ARTICLES}`, false);
	}

	addToWishList() {
		return this.addToCart(true);
	}

	addToCart(isWishlist = false) {
		const itemsToAddToCart = this.model.selectedSwatches;

		if (itemsToAddToCart.length) {
			const endpoint = ENDPOINTS[this.model.orderSettings.addToCartType];
			const data = {};
			let urlPrefix = isWishlist ? 'wishlist/' : 'shopping/';

			this.addToCartType = isWishlist ? 'WISHLIST' : 'CART';

			if (this.model.orderSettings.addToCartType === 'SWATCHES') {
				urlPrefix = '';
				data.materialIds = itemsToAddToCart.map((swatch) => {
					return swatch.materialId;
				});
			} else {
				data.productRequestDtos = itemsToAddToCart.map((swatch) => {
					return { articleNumber: swatch.sampleArticleNumber };
				});
			}

			return this.addSwatchesToCart(data, `${urlPrefix}${endpoint}`, true, isWishlist).then(() => {
				this.globalDynamicStore.fetchData();
				this.deselectAllSwatches();
			});
		}
		this.model.showSelectionRequiredError = true;
		return Promise.reject(new Error('No swatches selected')).catch(error => error);
	}

	// eslint-disable-next-line default-param-last
	addSwatchesToCart(payload, endpoint, isSwatch = true, isWishlist) {
		const headers = {};
		if (isOnServer) {
			headers.Cookie = `SESSION=${this.cookies.SESSION}`;
		}
		if (isSwatch) {
			this.isAddToCartInProcess = true;
			this.isAddToCartDone = false;
			this.shouldButtonAnimate = true;
		} else {
			this.isAddSampleToCartInProcess = true;
			this.isAddSampleToCartDone = false;
			this.shouldSampleButtonAnimate = true;
		}
		return axios.request({
			url: `${apiUrl}/api/web/carts/${endpoint}`,
			method: 'POST',
			headers,
			data: payload,
		}).then((response) => {
			if (isSwatch) {
				this.isAddToCartDone = true;
				this.isAddToCartInProcess = false;
			} else {
				this.isAddSampleToCartDone = true;
				this.isAddSampleToCartInProcess = false;
			}

			if (isWishlist) {
				this.model.addSwatchestoWishlistResponse = response;
			} else {
				this.model.addSwatchesToCartResponse = response;
			}

			if (!isOnServer && cartChangedAction && cartChangedAction.getNumListeners()) {
				cartChangedAction.dispatch();
			}

			this.globalDynamicStore.fetchData();

			setTimeout(() => {
				this.stopAnimating(isSwatch);
			}, 3000);
			return response;
		}, this.addToCartErrorHandler);
	}

	stopAnimating(isSwatch = true) {
		if (isSwatch) {
			this.shouldButtonAnimate = false;
			this.isAddToCartDone = false;
		} else {
			this.shouldSampleButtonAnimate = false;
			this.isAddSampleToCartDone = false;
		}
	}

	addToCartErrorHandler({ response: { data: { errors = [] } } }) {
		this.isAddToCartDone = true;
		this.isAddToCartInProcess = false;
		this.isAddSampleToCartDone = true;
		this.isAddSampleToCartInProcess = false;
		setTimeout(this.stopAnimating, 3000);

		return Promise.reject(errors);
	}

	fetchData() {
		const headers = {};
		if (isOnServer) {
			headers.Cookie = `SESSION=${this.cookies.SESSION}`;
		}
		this.isLoading = true;
		return axios.request({
			url: `${apiUrl}${this.url}`,
			method: 'get',
			headers,
		}).then(this.processData, (error) => {
			this.isLoading = false;
			console.error(error);
		});
	}

	processData(response) {
		const {
			data,
			data: {
				swatchGroups = [],
			} = {},
		} = response || {};

		const groups = swatchGroups.map((group) => {
			const groupModel = SwatchGroupModelFactory.create(group);
			groupModel.swatches = group.swatches.map((swatch) => {
				return SwatchModelFactory.create(swatch, groupModel.detailsMap);
			});
			return groupModel;
		});

		this.model.update(data, groups);
		this.preSelectSwatches();

		this.magicModal.alterModal({ title: this.model.title });

		const trLinkEventCompName = this.model.groups[0].data.title || this.model.groups[0].data.swatches[0].name || null;

		if (trLinkEventCompName) {
			this.magicModal.alterModal({ trLinkEventCompName: `${trLinkEventCompName} swatch viewer` });
		}

		this.isLoading = false;
	}

	deselectAllSwatches() {
		this.model.showSelectionRequiredError = false;
		this.model.selectedSwatches.forEach((swatch) => {
			swatch.isSelected = false;
		});
	}

	selectSwatch(newSwatch) {
		this.model.showSelectionRequiredError = false;
		this.model.isFullDetailsExpanded = false;
		this.model.isResponsibleDetailsExpanded = false;
		if (this.model.selectedSwatch) {
			this.model.selectedSwatch.isSelected = false;
		}
		newSwatch.isSelected = true;
	}

	toggleSwatchSelected(swatch) {
		this.model.showSelectionRequiredError = false;
		swatch.isSelected = !swatch.isSelected;
	}

	toggleFullDetails() {
		this.model.isFullDetailsExpanded = !this.model.isFullDetailsExpanded;
	}

	toggleResponsibleDetails() {
		this.model.isResponsibleDetailsExpanded = !this.model.isResponsibleDetailsExpanded;
	}
}

const SwatchViewerStoreFactory = {
	create(url, orderContentType, cookies, magicModal, globalDynamicStore, HREF, productGroupKey, featureTogglesModel, breadcrumbModels) {
		const instance = new SwatchViewerStore();

		instance.HREF = HREF;
		instance.isLoading = false;
		instance.url = url;
		instance.cookies = cookies;
		instance.magicModal = magicModal;
		instance.globalDynamicStore = globalDynamicStore;
		instance.model = SwatchModalModelFactory.create(orderContentType, breadcrumbModels);
		instance.orderContentType = orderContentType;
		instance.renderMode = 'ORDER';
		instance.productGroupKey = productGroupKey;
		instance.featureTogglesModel = featureTogglesModel;

		return instance;
	},
};

export {
	SwatchViewerStoreFactory,
};
