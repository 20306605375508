import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { isOnServer } from '~/global/global.constants';
import { useSwatchViewer } from '~/product/common/swatchViewer/Contexts/SwatchViewer.context';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

import stylesSwatchViewer from '~/product/common/swatchViewer/Components/swatch-viewer.module.scss';

const SwatchDetailsModal = dynamic(
	() => import('~/product/common/swatchViewer/Components/SwatchDetails.modal').then(module => module.SwatchDetailsModal),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const SwatchOrderModal = dynamic(
	() => import('~/product/common/swatch-order/Components/SwatchOrder.modal').then(module => module.SwatchOrderModal),
	{ loading: () => <LoadingSpinner isLoading /> },
);

export const SwatchViewerModal = observer(() => {
	const {
		store: {
			isLoading = false,
			renderMode = 'ORDER'
		} = {},
	} = useSwatchViewer();

	const minHeight = 400;
	const holder = useRef();
	const resizeModal = () => {
		if (!isOnServer) {
			const selector = renderMode === 'ORDER' ? '.swatch-order-modal-container' : '.swatch-detail-modal-container';
			const height = document.querySelector(selector)?.offsetHeight;
			if (height >= minHeight) {
				holder.current.style.minHeight = `${height}px`;
			}
		}
	};

	// will mount / will unmount
	useEffect(() => {
		const height = holder.current.offsetHeight;
		if (height > 0) {
			holder.current.style.minHeight = `${height}px`;
		}
	}, []);

	// will update / did update
	useEffect(resizeModal);

	return (
		<div className={stylesSwatchViewer['swatch-viewer-modal']} ref={holder}>
			<SwitchTransition mode="out-in">
				<CSSTransition
					key={`${renderMode}-${isLoading}`}
					timeout={200}
					classNames="fade"
					addEndListener={(node, done) => {
						if (!isOnServer) {
							node.addEventListener('transitionend', done, false);
						}
					}}
				>
					<div>
						{
							isLoading &&
							<LoadingSpinner key="loading-spinner" isLoading={isLoading} />
						}
						{
							!isLoading && renderMode === 'DETAIL' &&
							<SwatchDetailsModal resizeModal={resizeModal} />
						}
						{
							!isLoading && renderMode === 'ORDER' &&
							<SwatchOrderModal resizeModal={resizeModal} />
						}
					</div>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
});
