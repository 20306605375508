const showHideDefaults = {
	wishListButton: false,
	cartLink: true,
	continueShoppingLink: true,
	contentTypeLink: true,
};

export const SWATCH_ORDER_TYPES = {
	MATERIAL: {
		DEFAULT: {
			modalContent: {
				listItems: [],
				showHide: showHideDefaults,
				modalTitle: 'Free 5" x 5" Photo Cards',
			},
			addToCartType: 'SWATCHES',
		},
		PAID_TOP_SAMPLE: {
			modalContent: {
				listItems: [
					'Sample fees refunded if returned within six weeks.',
					'Pre-paid return shipping label included.',
				],
				showHide: {
					...showHideDefaults,
					wishListButton: true,
				},
				modalTitle: '$25 Material Samples',
			},
			addToCartType: 'ARTICLES',
		},
		PAID_WOOD_SAMPLE: {
			modalContent: {
				listItems: [
					'Sample fees refunded if returned within six weeks.',
					'Pre-paid return shipping label included.',
				],
				showHide: {
					...showHideDefaults,
					wishListButton: true,
				},
				modalTitle: '$25 Material Samples',
			},
			addToCartType: 'ARTICLES',
		},
		PAID_MATERIAL_SAMPLE: {
			modalContent: {
				listItems: [
					'Sample fees refunded if returned within six weeks.',
					'Pre-paid return shipping label included.',
				],
				showHide: {
					...showHideDefaults,
					wishListButton: true,
				},
				modalTitle: '$25 Material Samples',
			},
			addToCartType: 'ARTICLES',
		},
	},
	FABRIC: {
		DEFAULT: {
			modalContent: {
				listItems: [],
				showHide: {
					...showHideDefaults,
					contentTypeLink: false,
				},
				modalTitle: 'Free 4" x 4" Swatches',
			},
			addToCartType: 'SWATCHES',
		},
	},
	LEATHER: {
		DEFAULT: {
			modalContent: {
				listItems: [],
				showHide: {
					...showHideDefaults,
					contentTypeLink: false,
				},
				modalTitle: 'Free 3" x 3" Swatches',
			},
			addToCartType: 'SWATCHES',
		},
	},
	RUG: {
		DEFAULT: {
			modalContent: {
				listItems: [
					'Sample fees refunded if returned within six weeks.',
					'Pre-paid return shipping label included.',
				],
				showHide: {
					...showHideDefaults,
					wishListButton: true,
					contentTypeLink: false,
				},
				modalTitle: '12"x16" Rug Samples for $25',
			},
			addToCartType: 'ARTICLES',
		},
	},
};
