import React from 'react';
import { observer } from 'mobx-react';

import styles from './select-list-modal.module.scss';

import { FavoritesListSummary } from '~/favorites/Models/FavoritesListSummary.model';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { s7ContentPath } from '~/global/global.constants';
import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';

interface Props {
	store: AddToFavoritesStore
	list: FavoritesListSummary
}

export const ListOption = observer((props: Props) => {
	const {
		store,
		store: {
			formModel,
		} = {},
		list: {
			id,
			defaultList,
			name,
			firstLetterOfName,
			hasImage: listHasImage,
			image: {
				imageSrc = '',
				imageSrcSet = '',
			} = {},
		},
	} = props;

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.updateSelectedState(id, event.target.checked);
	};

	const inputAttributes: any = {
		defaultChecked: Boolean(formModel[id]),
	};

	return (
		<li
			className={`${styles['list-option']} ${formModel[id] ? styles['selected-option'] : ''}`}
			data-qa={`list-option-${name}`}
			key={`favorite-container-${id}`}
		>
			<label
				className={styles['list-label']}
				data-qa="list-option-label"
				htmlFor={`checkbox-item-${id}`}
			>
				<div className={styles['list-image']} data-qa="list-option-image">
					{
						listHasImage &&
						<LazyImage
							src={imageSrc}
							srcSet={imageSrcSet}
						/>
					}
					{
						!listHasImage && defaultList &&
						<img className={styles['heart-icon']} alt="" src={`${s7ContentPath}/black_heart_icon`} />
					}
					{
						!listHasImage && !defaultList &&
						<span className="tw-text-xl tw-font-light tw-capitalize">{firstLetterOfName}</span>
					}
				</div>
				<div className={styles['list-name']} data-qa="list-option-name">
					{name}
				</div>
				<div className="tw-text-center">
					<input
						type="checkbox"
						className={styles['checkbox']}
						data-qa="list-option-checkbox"
						id={`checkbox-item-${id}`}
						onChange={onChangeHandler}
						{...inputAttributes}
					/>
				</div>
			</label>
		</li>
	);
});
