import React from 'react';
import { observer } from 'mobx-react';

import { StandardModal } from '~/components/magic-modal/Components/StandardModal';

import stylesSwatchViewer from '~/product/common/swatchViewer/Components/swatch-viewer.module.scss';

export const SwatchViewerModalWrapper = observer((props) => {
	return (
		<StandardModal className={stylesSwatchViewer['swatch-viewer']} store={props.store} />
	);
});
