export const addProductsToCartErrorKeys = {
	unauthorizedWishlist: 'unauthorizedWishlistModificationError',
};

export const swatchViewerModalSettings = {
	DETAIL: {
		id: 'swatch-details-modal',
		showHeader: false,
		showCloseButton: false,
		maxWidth: '900px',
	},
	ORDER: {
		id: 'swatch-details-modal',
		showHeader: true,
		showCloseButton: true,
		maxWidth: '700px',
	},
};

export const swatchTypeMessages = {
	FABRIC: 'Free Fabric',
	LEATHER: 'Free Leather',
	RUG: 'Rug Samples',
	DEFAULT: 'Free Photo Cards',
};
