import React, { ComponentProps } from 'react';
import classNames from 'classnames';

import { ButtonBase } from './ButtonBase';
import styles from './CloseButton.module.scss';

export interface IProps extends ComponentProps<typeof ButtonBase> {
	size?: number
}

export const CloseButton = (props: IProps) => {
	const { size = 30, ...buttonProps } = props;

	return (
		<ButtonBase
			{...buttonProps}
			aria-label={'Close'}
			className={classNames({
				'tw-flex tw-items-center tw-justify-center': true,
				[styles.closeButton]: true
			})}
			style={{ width: `${size}px`, height: `${size}px` }}
		>
			<svg
				width={`${size * 0.5}px`}
				height={`${size * 0.5}px`}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 15 15"
			>
				<path
					style={{ stroke: 'rgb(51, 51, 51)', strokeWidth: 1, fill: 'none' }}
					d="M0 0 L15 15"
				/>
				<path
					style={{ stroke: 'rgb(51, 51, 51)', strokeWidth: 1, fill: 'none' }}
					d="M15 0 L0 15"
				/>
			</svg>
		</ButtonBase>
	);
};
