import React, { createContext, useContext } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { SwatchViewerStoreFactory } from '~/product/common/swatchViewer/Stores/SwatchViewer.store';

const swatchViewerContext = createContext();

export const SwatchViewerProvider = ({
	children, apiUrl, breadcrumbModels = [], renderMode = 'DETAIL', orderContentType = 'DEFAULT', productGroupKey = undefined,
}) => {
	const {
		magicModal,
		cookies,
		featureTogglesModel,
		globalDynamicStore,
		HREF,
	} = useGlobalContext();

	const store = SwatchViewerStoreFactory.create(
		apiUrl,
		orderContentType,
		cookies,
		magicModal,
		globalDynamicStore,
		HREF,
		productGroupKey,
		featureTogglesModel,
		breadcrumbModels,
	);
	const swatchViewerObjects = {
		store,
		model: store.model,
		magicModal,
	};

	store.renderMode = renderMode;
	store.fetchData();
	return <swatchViewerContext.Provider value={swatchViewerObjects}>{children}</swatchViewerContext.Provider>;
};

export const useSwatchViewer = () => useContext(swatchViewerContext);
