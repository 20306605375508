import React, { type ComponentClass, type FunctionComponent } from 'react';

import type { LINE_ITEM_MESSAGE } from '~/util/messaging/line-item/Models/LineItemMessage.constants';
import type { ContextKey } from '~/util/messaging/Messaging.type';

import css from '~/util/messaging/line-item/LineItemMessaging.module.scss';

type Props = {
	context?: ContextKey
	messageKey?: string
	readOnly?: boolean
}
type LineItemMessageMap = {
	[index in LINE_ITEM_MESSAGE]: ComponentClass<any, any> | FunctionComponent<any>
}

export const LineItemMessages: LineItemMessageMap = {
	MISSING: ({ messageKey = '' }: Props) => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_MISSING">
				Missing component for message key: "{messageKey}"
			</div>
		);
	},
	LINE_ITEM_MESSAGE_100: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_100">
				Showroom item
			</div>
		);
	},
	LINE_ITEM_MESSAGE_110: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_110">
				Floor sample item. Final sale.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_120: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_120">
				Mattress returns or exchanges will not be accepted after 120 days.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_130: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_130">
				Room & Board does not provide vanity installation.
				Professional installation recommended.
				Vanities cannot be returned once altered or installed.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_140: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_140">
				Non-refundable fee of 30% applies to custom products that are changed, canceled or returned.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_150: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_150">
				Returns of unopened paint are accepted within 30 days of purchase.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_160: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_160">
				Custom window treatments cannot be returned because they are made to specific measurements.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_170: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_170">
				Outlet item. Final sale.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_180: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_180">
				Available in store only.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_190: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_190">
				Exceeded availability.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_200: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_200">
				This item is not available in the quantity you’ve requested. Please contact us for more information.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_210: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_210">
				Assembly required and professional installation recommended. Cannot be returned once altered or
				installed.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_220: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_220">
				Mattress recycling fee.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_230: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_230">
				Paint recycling fee.
			</div>
		);
	},
	LINE_ITEM_MESSAGE_240: ({ readOnly }: Props) => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_240">
				<div>
					This item hasn't arrived in our warehouse yet. Your delivery timing may not be impacted. Call{' '}
					{
						readOnly
							? '800.301.9720'
							: <a href="tel:8003019720">800.301.9720</a>
					}
					{' '}with questions.
				</div>
			</div>
		);
	},
	LINE_ITEM_MESSAGE_250: () => {
		return (
			<div className={css.lineItemMessage} data-qa="LINE_ITEM_MESSAGE_250">
				Assembly: Unit must be installed to wall with included wall bracket (installation not provided by
				delivery associates).
			</div>
		);
	},
};
