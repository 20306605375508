import {
	computed, observable, makeObservable,
} from 'mobx';

class SwatchGroupModel {
	data; // Mobx Object

	swatches;

	constructor() {
		makeObservable(this, {
			data: observable,
			swatches: observable,
			title: computed,
			subTitle: computed,
			showHeader: computed,
			selectedSwatch: computed,
			selectedSwatches: computed,
			detailsMap: computed,
		});
	}

	get title() {
		return this.data.title;
	}

	get subTitle() {
		return this.data.subTitle;
	}

	get showHeader() {
		return Boolean(this.title) && Boolean(this.subTitle);
	}

	get selectedSwatch() {
		return this.selectedSwatches[0];
	}

	get selectedSwatches() {
		if (!this.swatches || !this.swatches.length) {
			return [];
		}
		return this.swatches.filter((swatch) => {
			return swatch.isSelected;
		});
	}

	get detailsMap() {
		return this.data.swatchDetailDataMap;
	}
}

const SwatchGroupModelFactory = {
	create: (data) => {
		const instance = new SwatchGroupModel();
		instance.data = data || {};

		return instance;
	},
};

export { SwatchGroupModelFactory };
